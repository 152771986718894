<script lang="ts" setup>
/**
 * @file Beethoven Attachment Preview
 * Barebone component that fetches Beethoven Data and loads the preview image:
 * 1. Fetches preview info from beethoven.
 * 2. Preview Data is loaded, emit `load-data` then load image using ImageThumbnail
 * 3. Image is loaded, emit `load-image`
 */

import {
  fallbackAttachmentSizeMapping,
  FallbackAttachmentType,
  fallbackImageUrl,
  getExtraMetadataText,
  getMetadataText,
  shouldDisplayMetadataTag,
} from '@@/bits/attachments'
import { getDisplayAttributes } from '@@/bits/beethoven'
import { dir } from '@@/bits/current_dir'
import { isTweet as hasTweetAttachment } from '@@/bits/post_attachment'
import { PADLET_DOMAIN_REGEX } from '@@/bits/regex'
import { defineAsyncComponent } from '@@/bits/vue'
import ImageThumbnail from '@@/library/v4/components/ImageThumbnail.vue'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceAttachmentsStore } from '@@/pinia/surface_attachments'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useWindowSizeStore } from '@@/pinia/window_size'
import type { PostColor } from '@@/types'
import BeethovenAttachmentMetadataSlope from '@@/vuecomponents/BeethovenAttachmentMetadataSlope.vue'
import type { BeethovenDisplayAttributes } from '@padlet/beethoven-client'
import { LinkHelpers } from '@padlet/beethoven-client'
import { processedUrl } from '@padlet/vivaldi-client'
import { pickBy } from 'lodash-es'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    url: string
    usageContext: 'postModal' | 'postView' | 'postPreview'
    /**
     * Used to query attachment props belonging to a post.
     */
    postCid: string
    /** @default 508 */
    width?: number
    /** @default undefined */
    height?: number
    /**
     * Show border in case the preview is loaded on a background that requires it so that it won't blend in too much.
     * For example set this to true if loading a PDF on the white modal.
     * @default false
     */
    xBorder?: boolean
    /**
     * Default to show metadata slope, can hide if needed
     * @default true
     */
    xMetadata?: boolean
    /**
     * How the component should decide to show dark mode: Using browser or with custom isDark prop
     * @default 'browser'
     */
    darkThemeType?: 'browser' | 'custom'
    isDark?: boolean
    lazyLoading?: boolean
    backgroundColor?: PostColor
    borderRadiusClass?: string
    xHoverOverlay?: boolean
    fetchPriority?: 'auto' | 'high' | 'low'
    customThumbnailUrl?: string | undefined
  }>(),
  {
    width: 508,
    height: undefined,
    xBorder: false,
    xMetadata: true,
    darkThemeType: 'browser',
    isDark: false,
    lazyLoading: true,
    backgroundColor: undefined,
    borderRadiusClass: undefined,
    xHoverOverlay: false,
    fetchPriority: 'auto',
    customThumbnailUrl: undefined,
  },
)

const emit = defineEmits<{
  (event: 'error', error: Error): void
  (event: 'load-data', data: any): void
  (event: 'load-image'): void
}>()

const SurfaceAttachmentTweet = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceAttachmentTweet.vue'))

const windowSizeStore = useWindowSizeStore()
const surfaceDraftsStore = useSurfaceDraftsStore()
const surfaceAttachmentsStore = useSurfaceAttachmentsStore()
const surfaceStore = useSurfaceStore()

const { isStream, isCanvas, isWhiteboard } = storeToRefs(surfaceStore)
const { getAttachmentPropsFromDraft, updateDraftWishContentAttachmentProps } = surfaceDraftsStore
const { updateLinkDisplayAttributes, getAttachmentPropsFromPost } = surfaceAttachmentsStore

const link = ref<BeethovenDisplayAttributes | null>(null)
const isLoaded = ref(false)
const isError = ref(false)
const isImageLoaded = ref(false)
// Note: need to initialize these values for reactivity of getter based on them to work
const imageUrl = ref<string | null>(null)
const imageColor = ref<string>()

// Default to size of fallback image (images/fallback_file.png)
const thumbnailWidth = ref(3000)
const thumbnailHeight = ref(1500)

const isUsingInPostModal = computed(() => props.usageContext === 'postModal')
const attachmentProps = computed(() => {
  if (props.usageContext === 'postPreview') {
    return null
  } else if (isUsingInPostModal.value) {
    return getAttachmentPropsFromDraft(props.postCid)
  } else {
    return getAttachmentPropsFromPost(props.postCid)
  }
})

const xAlternativeText = computed(
  () => link.value != null && LinkHelpers.isPhoto(link.value) && attachmentProps.value?.alternative_text != null,
)

const isTweet = computed(() => !isWhiteboard.value && hasTweetAttachment(attachmentProps.value))

const isOriginalAttachmentFallback = computed(() => {
  if (isTweet.value) return false
  return isError.value || link.value?.is_fallback || (!!imageUrl.value && imageUrl.value.startsWith('data:'))
})

const isCustomThumbnailFallback = ref(false)

const isPreviewImageTransparent = computed(() => {
  return link.value?.is_transparent || false
})

const previewImageSrc = computed(() => {
  // if there is a custom thumbnail use it to determine which preview image to show instead of original attachment
  if (props.customThumbnailUrl) {
    if (imageUrl.value && !isCustomThumbnailFallback.value) return imageUrl.value
  } else {
    if (imageUrl.value && !isOriginalAttachmentFallback.value) return imageUrl.value
  }
  const fallbackImgSrc = fallbackImageUrl(
    fallbackAttachmentType.value,
    props.isDark ? 'dark' : 'light',
    props.backgroundColor,
  )
  if (isCanvas.value) return fallbackImgSrc
  return processedUrl(fallbackImgSrc, { width: fallbackWidth.value })
})

const fallbackWidth = computed(() => {
  if (isStream.value) return 1032 // 2 x 516
  return 476 // 2 x 238
})

const isCroppingVideoPreview = computed(() => {
  return !!link.value && LinkHelpers.isVideo(link.value) && !!link.value.image_aspect_ratio
})

const videoCropRatio = computed(() => {
  if (link.value?.provider_name?.toLowerCase() === 'youtube') {
    return 16 / 9
  }
  return link.value?.image_aspect_ratio || 4 / 3
})

const isBrokenPadletLink = computed(() => {
  if (!props.url) return false
  if (!PADLET_DOMAIN_REGEX.test(props.url)) return false
  return (
    !link.value ||
    (link.value.content_category === 'page' &&
      link.value.content_subcategory === 'padlet' &&
      !link.value.original_image_url)
  )
})

// See https://stackoverflow.com/c/padlet/questions/705 and resource_manager.rb#fallback_peek
// This fallback image is shown everywhere outside of surface.
// On surface, we want to show a different set of fallback images defined in bits/attachments.ts#fallbackImageUrl
const isFallbackPeekLink = computed(() => {
  const fallbackPeekFilename = 'padlet_preview_1200x630'
  return !!imageUrl.value && imageUrl.value.includes(fallbackPeekFilename)
})

const fallbackAttachmentType = computed(() => {
  if (isBrokenPadletLink.value || isFallbackPeekLink.value) return FallbackAttachmentType.BrokenPadletLink
  if (!link.value) return FallbackAttachmentType.BrokenLink
  if (LinkHelpers.isAudio(link.value)) return FallbackAttachmentType.Audio
  if (LinkHelpers.isVideo(link.value)) return FallbackAttachmentType.Video
  if (LinkHelpers.isPhoto(link.value)) return FallbackAttachmentType.Image
  if (LinkHelpers.isFile(link.value)) return FallbackAttachmentType.File
  if (LinkHelpers.isExcelSpreadsheet(link.value)) return FallbackAttachmentType.Spreadsheet
  if (LinkHelpers.isPowerpointPresentation(link.value)) return FallbackAttachmentType.Presentation
  if (LinkHelpers.isGoogleDoc(link.value)) return FallbackAttachmentType.GoogleDocument
  if (LinkHelpers.isDocument(link.value)) return FallbackAttachmentType.TextDocument
  if (LinkHelpers.isPage(link.value)) return FallbackAttachmentType.Link
  return FallbackAttachmentType.Link
})

const hasMetadata = computed(() => {
  if (!link.value) return false
  return shouldDisplayMetadataTag(link.value) && isImageLoaded.value
})

const metadataText = computed(() => {
  if (!link.value) return ''
  return getMetadataText(link.value)
})

const extraMetadataText = computed(() => {
  if (!link.value) {
    return ''
  }
  return getExtraMetadataText(link.value)
})

const useSizeDataForFallbackImage = () => {
  isImageLoaded.value = true
  const fallbackImageSize = fallbackAttachmentSizeMapping[fallbackAttachmentType.value]
  thumbnailWidth.value = fallbackImageSize.width
  thumbnailHeight.value = fallbackImageSize.height
}

function handleBeethovenError(error: Error): void {
  emit('error', error)
  isError.value = true
  useSizeDataForFallbackImage()
}

async function getBeethovenAttributes() {
  const locale = 'en'
  const bhOptions = {
    dpr: window.devicePixelRatio,
    locale,
    width: props.width,
    height: props.height || undefined,
  }

  isError.value = false

  let data
  try {
    data = await getDisplayAttributes(props.url, bhOptions)
    if (data === null) {
      handleBeethovenError(new Error('No data.'))
    }
    if (data.error) {
      handleBeethovenError(data.error)
    }
  } catch (error) {
    handleBeethovenError(error)
  }

  if (isError.value) return

  link.value = data
  let customThumbnailDisplayAttributes: Partial<BeethovenDisplayAttributes> = {}
  if (props.customThumbnailUrl) {
    const customThumbnailData = await getDisplayAttributes(props.customThumbnailUrl)
    isCustomThumbnailFallback.value = !!customThumbnailData?.is_fallback
    customThumbnailDisplayAttributes = {
      image_aspect_ratio: customThumbnailData?.image_aspect_ratio,
      image_color: customThumbnailData?.image_color,
      image_height: customThumbnailData?.image_height,
      image_width: customThumbnailData?.image_width,
      image_luminance: customThumbnailData?.image_luminance,
      image_url: customThumbnailData?.image_url,
      is_transparent: customThumbnailData?.is_transparent,
    }
    customThumbnailDisplayAttributes = pickBy(
      customThumbnailDisplayAttributes,
      (_key, value) => value !== undefined || value !== null,
    )

    updateLinkDisplayAttributes({ url: props.url, attributes: { ...data, ...customThumbnailDisplayAttributes } })
    updateDraftWishContentAttachmentProps({
      cid: props.postCid,
      url: props.url,
      attachmentProps: {
        ...getAttachmentPropsFromDraft(props.postCid),
        ...data.metadata?.attachment_props,
      },
    })
  } else {
    isCustomThumbnailFallback.value = false
    updateLinkDisplayAttributes({ url: props.url, attributes: data })
    updateDraftWishContentAttachmentProps({
      cid: props.postCid,
      url: props.url,
      attachmentProps: data.metadata?.attachment_props,
    })
  }

  if (isWhiteboard.value || data.metadata?.attachment_props?.type !== 'tweet') {
    // For tweets, we don't want to set the loaded state
    // yet when LinksController API result comes. This is
    // to prevent a case when a user publishes the post
    // before the link extraction completes, causing the
    // default preview to be shown briefly before a realtime
    // message comes and the tweet preview is rendered.
    isLoaded.value = true
    emit('load-data', data)
  }
  imageUrl.value = customThumbnailDisplayAttributes?.image_url ?? data.image_url
  imageColor.value = customThumbnailDisplayAttributes?.image_color ?? data.image_color
  thumbnailWidth.value = customThumbnailDisplayAttributes?.image_width ?? data.image_width
  thumbnailHeight.value = customThumbnailDisplayAttributes?.image_height ?? data.image_height

  // if there is a custom thumbnail use custom thumbnail for handling fallback image instead of original attachment
  if (props.customThumbnailUrl) {
    if (customThumbnailDisplayAttributes.is_fallback) {
      useSizeDataForFallbackImage()
    }
    return
  }

  if (isOriginalAttachmentFallback.value) {
    useSizeDataForFallbackImage()
  }
}

function handleImageLoaded() {
  isImageLoaded.value = true
  emit('load-image')
}

watch([() => props.width, () => props.url, () => props.customThumbnailUrl], getBeethovenAttributes)

onMounted(() => {
  // If we receive attachment props of a tweet,
  // we don't need to call Links API anymore.
  // Attachment props should have enough data
  // to render the tweet.
  if (isTweet.value) {
    // However, we should still emit data as if
    // we received some data from Links API so
    // the parent component's behavior isn't
    // broken.
    isLoaded.value = true
    emit('load-data', {
      url: props.url,
      provider_name: 'Twitter',
      content_category: 'page',
      content_subcategory: 'html',
      image_color: '',
    })
  } else {
    getBeethovenAttributes()
  }
})
</script>

<template>
  <!-- Need !isImageLoaded && 'opacity-0' so this won't show a small pixel with background color when Beethoven extraction result is loaded but the image file is not loaded yet -->
  <SurfaceAttachmentTweet
    v-if="isTweet && attachmentProps"
    class="w-full"
    :attachment-props="attachmentProps"
    :usage-context="usageContext"
    :width="width"
    :color="backgroundColor"
    :dark-mode="isDark"
    :is-stream="isStream"
    :lazy-loading="lazyLoading"
    :is-whiteboard="isWhiteboard"
    :use-short-date-format="windowSizeStore.isSmallerThanTabletPortrait"
    @image-loaded="handleImageLoaded"
  />
  <div v-else :class="['relative', 'overflow-hidden', borderRadiusClass]">
    <ImageThumbnail
      v-if="isLoaded || isError"
      :key="previewImageSrc"
      :class="[
        'max-w-full',
        borderRadiusClass,
        {
          'border border-solid': xBorder,
          'border-attachment-preview-border-light ': xBorder && (darkThemeType == 'browser' || !isDark),
          'dark:border-attachment-preview-border-dark': xBorder && darkThemeType == 'browser',
          'border-attachment-preview-border-dark': xBorder && darkThemeType === 'custom' && isDark,
          hidden: isUsingInPostModal && !isImageLoaded,
        },
      ]"
      :alt="xAlternativeText && attachmentProps ? attachmentProps.alternative_text : ''"
      :width="width - (xBorder ? 2 : 0)"
      :src="previewImageSrc"
      :original-image-width="thumbnailWidth - (xBorder ? 2 : 0)"
      :original-image-height="thumbnailHeight - (xBorder ? 2 : 0)"
      :aspect-ratio="isCroppingVideoPreview ? videoCropRatio : undefined"
      :lazy-loading="lazyLoading"
      placeholder-color="transparent"
      :placeholder-color-value="isPreviewImageTransparent ? undefined : imageColor"
      :fetch-priority="fetchPriority"
      @load="handleImageLoaded"
      @error="handleBeethovenError"
    />
    <div
      v-if="xHoverOverlay"
      :class="{
        'absolute inset-0 opacity-0 hover-hover:hover:opacity-100 transition-opacity duration-250': true,
        'bg-attachment-overlay-light': !isDark,
        'bg-attachment-overlay-dark': isDark,
      }"
    />
    <slot name="adornment" />
    <!-- Note: 100% - 8rem only works when the wrapper has position: relative -->
    <BeethovenAttachmentMetadataSlope
      v-if="xMetadata && hasMetadata"
      class="max-w-[calc(100%-2.5rem)]"
      :color="backgroundColor"
      :dark-theme-type="darkThemeType"
      :is-dark="isDark"
      :title="metadataText"
      :usage-context="usageContext"
      :is-whiteboard="isWhiteboard"
    >
      <div :dir="dir()" class="truncate font-sans flex flex-row">
        <span class="truncate">{{ metadataText }}</span>
        <template v-if="extraMetadataText">
          <span :class="{ 'text-light-text-300': isDark, 'text-dark-text-300': !isDark, 'px-1': true }"> • </span>
          <span>{{ extraMetadataText }}</span>
        </template>
      </div>
    </BeethovenAttachmentMetadataSlope>
  </div>
</template>
