<script lang="ts" setup>
import { dir } from '@@/bits/current_dir'
import type { PostColor } from '@@/types'
import { computed } from 'vue'
const props = withDefaults(
  defineProps<{
    usageContext: 'postModal' | 'postView' | 'postPreview'
    darkThemeType?: 'browser' | 'custom'
    isDark?: boolean
    isWhiteboard: boolean
    color?: 'default' | NonNullable<PostColor>
  }>(),
  {
    darkThemeType: 'browser',
    isDark: false,
    isWhiteboard: false,
    color: 'default',
  },
)
const isBrowserDecidingDarkMode = computed(() => props.darkThemeType === 'browser')
const isForcedLight = computed(() => props.darkThemeType === 'custom' && !props.isDark)
const isForcedDark = computed(() => props.darkThemeType === 'custom' && props.isDark)
const wavySvgSize = computed(() => (props.isWhiteboard ? 32 : 25))
const lightMainBgColorClass = computed(() => {
  switch (props.color) {
    case 'red':
      return 'bg-scarlet-200'
    case 'orange':
      return 'bg-canary-200'
    case 'green':
      return 'bg-park-200'
    case 'blue':
      return 'bg-oceanic-200'
    case 'purple':
      return 'bg-grape-200'
    case 'default':
    default:
      return 'bg-light-ui-200'
  }
})

const darkMainBgColorClass = computed(() => {
  switch (props.color) {
    case 'red':
      return 'bg-scarlet-800'
    case 'orange':
      return 'bg-tangerine-800'
    case 'green':
      return 'bg-park-800'
    case 'blue':
      return 'bg-oceanic-800'
    case 'purple':
      return 'bg-grape-800'
    case 'default':
    default:
      return 'bg-dark-ui-200'
  }
})

const darkBrowserMainBgColorClass = computed(() => {
  switch (props.color) {
    case 'red':
      return 'dark:bg-scarlet-800'
    case 'orange':
      return 'dark:bg-tangerine-800'
    case 'green':
      return 'dark:bg-park-800'
    case 'blue':
      return 'dark:bg-oceanic-800'
    case 'purple':
      return 'dark:bg-grape-800'
    case 'default':
    default:
      return 'dark:bg-dark-ui-200'
  }
})

const lightSlopeColorClass = computed(() => {
  switch (props.color) {
    case 'red':
      return 'text-scarlet-200'
    case 'orange':
      return 'text-canary-200'
    case 'green':
      return 'text-park-200'
    case 'blue':
      return 'text-oceanic-200'
    case 'purple':
      return 'text-grape-200'
    case 'default':
    default:
      return 'text-light-ui-200'
  }
})

const darkSlopeColorClass = computed(() => {
  switch (props.color) {
    case 'red':
      return 'text-scarlet-800'
    case 'orange':
      return 'text-tangerine-800'
    case 'green':
      return 'text-park-800'
    case 'blue':
      return 'text-oceanic-800'
    case 'purple':
      return 'text-grape-800'
    case 'default':
    default:
      return 'text-dark-ui-200'
  }
})

const darkBrowserSlopeColorClass = computed(() => {
  switch (props.color) {
    case 'red':
      return 'dark:text-scarlet-800'
    case 'orange':
      return 'dark:text-tangerine-800'
    case 'green':
      return 'dark:text-park-800'
    case 'blue':
      return 'dark:text-oceanic-800'
    case 'purple':
      return 'dark:text-grape-800'
    case 'default':
    default:
      return 'dark:text-dark-ui-200'
  }
})
</script>

<template>
  <div :class="['flex', 'absolute', '-bottom-px', 'ltr:left-0', 'rtl:right-0']" :dir="dir()">
    <div
      :class="[
        {
          flex: true,
          'items-center': true,
          [lightMainBgColorClass]: isBrowserDecidingDarkMode || isForcedLight,
          'text-dark-text-100': isBrowserDecidingDarkMode || isForcedLight,
          [darkBrowserMainBgColorClass]: isBrowserDecidingDarkMode,
          'dark:text-light-text-100': isBrowserDecidingDarkMode,
          [darkMainBgColorClass]: isForcedDark,
          'text-light-text-100': isForcedDark,
          'text-body-extra-small': !isWhiteboard,
          'text-whiteboard-body-extra-small': isWhiteboard,
          'h-6': !isWhiteboard,
          'h-8': isWhiteboard,
          'ltr:pl-3': usageContext === 'postModal',
          'ltr:pl-2': usageContext !== 'postModal',
          'rtl:pr-3': usageContext === 'postModal',
          'rtl:pr-2': usageContext !== 'postModal',
          shrink: true,
          'min-w-0': true,
          'z-10': true,
        },
      ]"
    >
      <slot />
    </div>

    <!-- There is a bug with rendering SVG on webkit browsers (e.g. Safari), we need to add "transform scale-100" to fix it
    Reference: https://stackoverflow.com/questions/44170229/how-to-prevent-half-pixel-svg-shift-on-high-pixel-ratio-devices-retina -->
    <svg
      :class="['scale-100', 'rtl:scale-x-[-1]', 'shrink-0', '-translate-x-px', 'rtl:translate-x-px', 'z-0']"
      :width="wavySvgSize"
      :height="wavySvgSize"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- N.B somehow merging the classes objects doesn't change the slope color -- the classes all disappear -->
      <path
        d="M13 12C9.5 6.67572e-06 1 5.44637e-06 1 5.44637e-06L0 -0.00012207V25H25V24C25 24 16.5 24 13 12Z"
        :class="[
          'fill-current',
          { [lightSlopeColorClass]: isBrowserDecidingDarkMode || isForcedLight },
          { [darkBrowserSlopeColorClass]: isBrowserDecidingDarkMode },
          { [darkSlopeColorClass]: isForcedDark },
        ]"
      />
    </svg>
  </div>
</template>
